import TranslationWrapper from 'components/templates/Translation/TranslationWrapper';
import usePanelConfiguration from 'hooks/usePanelConfiguration';
import React from 'react';
import { EMAIL_REGEX, USERNAME_REGEX } from 'utils/formHelper';
import TextInput, { Props as TextInputProps } from './TextInput';

export type Props = {} & Omit<TextInputProps, 'id' | 'name' | 'title' | 'placeholder' | 'type'>;

export const FORM_KEY = 'username';
const namespace = 'common';

function UsernameInput(props: Props, ref: React.ForwardedRef<HTMLInputElement>) {
  const panelConfiguration = usePanelConfiguration();

  let title = 'Email Address';
  let placeholder = 'Enter Email Address';
  let autoComplete = 'username email';
  let pattern = EMAIL_REGEX.source;
  let errorMessage = 'Please enter a valid email address';

  if (panelConfiguration?.registrationOptions.phoneNumber) {
    title = 'Email Address or Phone Number';
    placeholder = 'Enter Email Address or Phone Number';
    autoComplete = 'username email tel';
    pattern = USERNAME_REGEX.source;
    errorMessage = 'Please enter a valid email address or phone number';
  }

  return (
    <TranslationWrapper namespace={namespace}>
      <TextInput
        id={FORM_KEY}
        name={FORM_KEY}
        title={title}
        placeholder={placeholder}
        type="text"
        required
        autoComplete={autoComplete}
        pattern={pattern}
        overrideErrorMessage={() => {
          return errorMessage;
        }}
        ref={ref}
        {...props}
      />
    </TranslationWrapper>
  );
}

const ForwardedUsernameInput = React.forwardRef<HTMLInputElement, Props>(UsernameInput);

export default ForwardedUsernameInput;
