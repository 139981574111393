import Login from 'components/pages/Login/Login';
import { buildPage, buildStaticProps } from 'utils/pageHelper';

export const getServerSideProps = buildStaticProps('login', {
  withoutHeader: true,
  withoutFooter: true,
});
export default buildPage(Login, {
  metaData: {
    title: 'Login',
    description: 'Please log in or sign up',
  },
});
