/**
 * @generated SignedSource<<646d4da51462e5895f3ea0fbe74f235e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ThirdPartyTrackingProviderCookieConsentOptionsQuery$variables = {};
export type ThirdPartyTrackingProviderCookieConsentOptionsQuery$data = {
  readonly cookies: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly consent: {
          readonly consented: boolean;
        } | null;
        readonly id: string;
      };
    } | null> | null;
  } | null;
};
export type ThirdPartyTrackingProviderCookieConsentOptionsQuery = {
  response: ThirdPartyTrackingProviderCookieConsentOptionsQuery$data;
  variables: ThirdPartyTrackingProviderCookieConsentOptionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CookieConnection",
    "kind": "LinkedField",
    "name": "cookies",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CookieEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Cookie",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CookieConsent",
                "kind": "LinkedField",
                "name": "consent",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "consented",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ThirdPartyTrackingProviderCookieConsentOptionsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ThirdPartyTrackingProviderCookieConsentOptionsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "80c76d50aeef6e0b15e1fa07ab2e49c7",
    "id": null,
    "metadata": {},
    "name": "ThirdPartyTrackingProviderCookieConsentOptionsQuery",
    "operationKind": "query",
    "text": "query ThirdPartyTrackingProviderCookieConsentOptionsQuery {\n  cookies {\n    edges {\n      node {\n        id\n        consent {\n          consented\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dca8675e8cad31ee6d6866d79a156f89";

export default node;
