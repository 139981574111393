import A from 'components/atoms/A/A';
import Alert from 'components/atoms/Alert/Alert';
import Button from 'components/atoms/Button/Button';
import LoadingOverlay from 'components/atoms/LoadingOverlay/LoadingOverlay';
import RegistrationCard from 'components/atoms/RegistrationCard/RegistrationCard';
import PasswordInput, {
  PASSWORD_REQUIREMENT_DESCRIPTIONS,
} from 'components/atoms/TextInput/PasswordInput';
import UsernameInput from 'components/atoms/TextInput/UsernameInput';
import Txt from 'components/atoms/Txt/Txt';
import Form from 'components/molecules/Form/Form';
import { DASHBOARD } from 'components/pages/pages';
import { useLogin } from 'components/templates/AuthenticationProvider/AuthenticationProvider';
import usePromiseState from 'hooks/usePromiseState';
import useQueryStringParams from 'hooks/useQueryStringParams';
import { useRouter } from 'next/router';
import React from 'react';
import SocialSignUpButtons from '../RegistrationForm/SocialSignUpButtons';
import BasicDetailsStepImage from './assets/login-img.svg';
import classes from './LoginForm.module.scss';

export type Props = {};

export default function LoginForm({}: Props) {
  const login = useLogin();
  const [doLogin, loginState] = usePromiseState(login);
  const [loginError, setLoginError] = React.useState<string | undefined>(undefined);
  const router = useRouter();
  const { error } = useQueryStringParams();

  const handleLogin = async (formData: FormData) => {
    if (formData.has('username') && formData.has('password')) {
      try {
        await doLogin(
          formData.get('username')?.toString() ?? '',
          formData.get('password')?.toString() ?? '',
        );
        await router.push(DASHBOARD.path, undefined, { shallow: true });
      } catch (err: any) {
        setLoginError(err.message);
      }
    }
  };

  // check for an error on the query from a login callback.
  React.useEffect(() => {
    if (error) {
      setLoginError(decodeURI(error));
    }
  }, [error]);

  return (
    <RegistrationCard
      title="More Surveys Please!"
      menu={
        <Txt component={'p'}>
          Not a LifePoints member?{' '}
          <A className={classes.signUpLink} href={'/registration'}>
            sign up
          </A>
        </Txt>
      }
      additionalInfo={{
        title: 'Sign in and continue earning',
        subtitle: (
          <Txt>
            There’s always a new survey opportunity waiting around the corner. Sign in now to keep
            taking surveys. The more LPs you collect, the bigger the rewards you’ll get.
          </Txt>
        ),
        image: <BasicDetailsStepImage />,
      }}
    >
      {loginState.isLoading && <LoadingOverlay />}
      {loginError && (
        <Alert
          title={'Please try again'}
          subtitle={loginError}
          type={'error'}
          onClose={() => {
            setLoginError(undefined);
          }}
        />
      )}
      <Txt component={'p'}>Enter your login details.</Txt>
      <Form
        submitDisabled={loginState.isLoading}
        submitName={'Sign in'}
        submitButtonType={'primary'}
        extraActionButtons={
          <Button
            buttonType={'text'}
            component={A}
            href={'/forgot-password'}
            className={classes.forgotPassword}
          >
            <Txt>Forgot password</Txt>
          </Button>
        }
        buttonWrapperClassName={classes.buttonWrapper}
        onSubmit={handleLogin}
      >
        <UsernameInput />
        <PasswordInput
          id={'password'}
          title={'Password'}
          placeholder={'Enter password'}
          required
          autoComplete={'current-password'}
          minLength={8}
          overrideErrorMessage={(_validity, _value) => {
            return PASSWORD_REQUIREMENT_DESCRIPTIONS;
          }}
        />
      </Form>
      <SocialSignUpButtons />
      <div className={classes.reportProblemWrapper}>
        <A className={classes.reportProblem} href="/member/zendeskhelp/new">
          <Txt>Report a problem</Txt>
        </A>
      </div>
    </RegistrationCard>
  );
}
